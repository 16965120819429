import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import bg from "images/library/restricted-section/bg.png";
import bg2 from "images/library/restricted-section/bg2.png";
import LibraryContainer from "components/LibraryContainer";
import useBooks from "hooks/useBooks";

const RestrictedLibrary = () => {
    const [password, setPassword] = useState("");

    const books = useBooks(true);
    const dispatch = useDispatch();

    const passwordValue = "lamiaatrox";
    const locked = password !== passwordValue;

    useEffect(() => {
        dispatch({ type: "layout/setBg", payload: !locked ? bg : bg2 });
        dispatch({ type: "layout/hasBgGradient", payload: false });
        dispatch({ type: "layout/removeBgOverlay" });
    }, [dispatch, locked]);

    return (
        <LibraryContainer
            books={books}
            theme="dark"
            locked={locked}
            setPassword={setPassword}
        />
    );
};

export default RestrictedLibrary;
