import { darken, lighten } from "polished";
import styled from "styled-components";

import darkWoodenBg from "images/library/restricted-section/wooden-bg.png";
import darkWoodenBorder from "images/library/restricted-section/wooden-border.png";
import woodenBg from "images/library/wooden-bg.png";
import woodenBorder from "images/library/wooden-border.png";

const darkBrown = "#1e2d4e";
const brown = "#694235";

const themes = {
    dark: {
        bg: darkWoodenBg,
        brown: darkBrown,
        border: darkWoodenBorder,
    },
    light: {
        bg: woodenBg,
        brown,
        border: woodenBorder,
    },
};

const Container = styled.div`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: auto;
    padding: 0 20px;
    border: 20px solid;
    border-image: url(${({ theme }) => themes[theme].border}) 64;
    overflow: hidden;
    background-image: linear-gradient(
        ${({ theme }) => darken(0.32, themes[theme].brown)},
        ${({ theme }) => darken(0.3, themes[theme].brown)} 220px,
        ${({ theme }) => lighten(0.04, themes[theme].brown)} 220px,
        ${({ theme }) => lighten(0.04, themes[theme].brown)} 222px,
        ${({ theme }) => themes[theme].brown} 222px,
        ${({ theme }) => themes[theme].brown} 228px,
        ${({ theme }) => darken(0.04, themes[theme].brown)} 228px,
        ${({ theme }) => darken(0.04, themes[theme].brown)} 230px
    );
    background-size: 10px 230px;
    box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.75);
    box-sizing: border-box;

    &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: url(${({ theme }) => themes[theme].bg}) center/50%;
        mask-image: linear-gradient(#000, #000 220px, transparent 220px);
        mask-size: 10px 230px;
        box-shadow: inset 0 0 5em 1em #000;
        filter: brightness(50%);
        content: "";
    }

    .books {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
`;

export default Container;
