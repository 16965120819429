import Anchor from "components/Anchor";
import Container from "./styles";
import textLogo from "images/text-logo.svg";

const Header = () => {
    const height = "2vh";

    return (
        <Container height={height}>
            <Anchor to="/">
                <img
                    src={textLogo}
                    alt="Hogwarts Heritage"
                    style={{ width: "9vw", height }}
                />
            </Anchor>
        </Container>
    );
};

export default Header;
