import styled from "styled-components";

import darkSpine1 from "images/library/restricted-section/spine1.png";
import darkSpine2 from "images/library/restricted-section/spine2.png";
import darkSpine3 from "images/library/restricted-section/spine3.png";
import darkSpine4 from "images/library/restricted-section/spine4.png";
import darkSpine5 from "images/library/restricted-section/spine5.png";
import darkSpine6 from "images/library/restricted-section/spine6.png";
import darkSpine7 from "images/library/restricted-section/spine7.png";
import darkSpine8 from "images/library/restricted-section/spine8.png";
import darkSpine9 from "images/library/restricted-section/spine9.png";
import darkSpine10 from "images/library/restricted-section/spine10.png";
import darkSpine11 from "images/library/restricted-section/spine11.png";
import darkSpine12 from "images/library/restricted-section/spine12.png";
import darkSpine13 from "images/library/restricted-section/spine13.png";
import spine1 from "images/library/spine1.png";
import spine2 from "images/library/spine2.png";
import spine3 from "images/library/spine3.png";
import spine4 from "images/library/spine4.png";
import spine5 from "images/library/spine5.png";
import spine6 from "images/library/spine6.png";
import spine7 from "images/library/spine7.png";
import spine8 from "images/library/spine8.png";
import spine9 from "images/library/spine9.png";
import spine10 from "images/library/spine10.png";
import spine11 from "images/library/spine11.png";
import spine12 from "images/library/spine12.png";
import spine13 from "images/library/spine13.png";

const themes = {
    dark: {
        spine1: darkSpine1,
        spine2: darkSpine2,
        spine3: darkSpine3,
        spine4: darkSpine4,
        spine5: darkSpine5,
        spine6: darkSpine6,
        spine7: darkSpine7,
        spine8: darkSpine8,
        spine9: darkSpine9,
        spine10: darkSpine10,
        spine11: darkSpine11,
        spine12: darkSpine12,
        spine13: darkSpine13,
    },
    light: {
        spine1,
        spine2,
        spine3,
        spine4,
        spine5,
        spine6,
        spine7,
        spine8,
        spine9,
        spine10,
        spine11,
        spine12,
        spine13,
    },
};

const spineBg = (bg) => `linear-gradient(
    90deg,
    rgb(0, 0, 0, 0.4),
    transparent,
    rgb(0, 0, 0, 0.5)
),
url(${bg}) center / cover`;

const Container = styled.div`
    .book {
        position: relative;
        width: 44px;
        height: 200px;
        color: white;
        font-size: 0.9em;
        margin-bottom: 10px;
        margin-top: 20px;
        border-left: 2px solid rgb(255, 255, 255, 0.1);
        border-right: 2px solid rgb(0, 0, 0, 0.3);
        transition: all 0.4s ease;
        overflow: hidden;
    }

    .book-title-wrapper {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 85%;
        overflow: hidden;
    }

    .book-title {
        position: relative;
        left: 50%;
        top: 50%;
        width: max-content;
        transform-origin: 0 0;
        transform: rotate(0.25turn) translate(-50%, -50%);
        text-transform: uppercase;
    }

    .book h2 {
        margin: 0;
        padding: 0;
        font-size: 1.1em;
    }

    .book h3 {
        margin: 0;
        padding: 0;
        font-size: 0.8em;
    }

    .book-tilted {
        float: left;
        width: 74px;
    }

    .book-tilted > .book {
        transform: translateY(-3px) translateX(15px) rotate(9deg);
    }

    .book:hover {
        transform: scale(1.05);
        box-shadow: 0px 0px 20px 7px rgba(0, 0, 0, 0.75);
        z-index: 99;
    }

    .book-1 {
        background: ${({ theme }) => spineBg(themes[theme].spine1)};
    }

    .book-2 {
        background: ${({ theme }) => spineBg(themes[theme].spine2)};
    }

    .book-3 {
        background: ${({ theme }) => spineBg(themes[theme].spine3)};
        filter: brightness(130%);
    }

    .book-4 {
        background: ${({ theme }) => spineBg(themes[theme].spine4)};
    }

    .book-5 {
        background: ${({ theme }) => spineBg(themes[theme].spine5)};
    }

    .book-6 {
        background: ${({ theme }) => spineBg(themes[theme].spine6)};
    }

    .book-7 {
        background: ${({ theme }) => spineBg(themes[theme].spine7)};
    }

    .book-8 {
        background: ${({ theme }) => spineBg(themes[theme].spine8)};
    }

    .book-9 {
        background: ${({ theme }) => spineBg(themes[theme].spine9)};
    }

    .book-10 {
        background: ${({ theme }) => spineBg(themes[theme].spine10)};
    }

    .book-11 {
        background: ${({ theme }) => spineBg(themes[theme].spine11)};
    }

    .book-12 {
        background: ${({ theme }) => spineBg(themes[theme].spine12)};
    }

    .book-13 {
        background: ${({ theme }) => spineBg(themes[theme].spine13)};
    }

    .title-1 {
        font-family: "Almendra", serif;
    }

    .title-2 {
        font-family: "Antonio", sans-serif;
    }

    .title-3 {
        font-family: "Cinzel Decorative", serif;
    }

    .title-4 {
        font-family: "Freeman", sans-serif;
    }

    .title-5 {
        font-family: "Sedan SC", serif;
    }
`;

export default Container;
