import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import AppLayout from "layouts/AppLayout";
import Home from "pages/Home";
import Hourglasses from "pages/Hourglasses";
import Library from "pages/Library";
import Reliquary from "pages/Reliquary";
import RestrictedLibrary from "pages/RestrictedLibrary";
import SkillCategories from "pages/SkillCategories";
import Skills from "pages/Skills";
import SkillsLayout from "layouts/SkillsLayout";

const AppRoutes = () => {
    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes location={location} key={location.key}>
                <Route path="/" element={<AppLayout />}>
                    <Route path="" element={<Home />} />
                    <Route path="habilidades" element={<SkillsLayout />}>
                        <Route path="" element={<SkillCategories />} />
                        <Route path=":category" element={<Skills />} />
                        <Route path=":category/:skill" element={<Skills />} />
                    </Route>
                    <Route path="biblioteca">
                        <Route path="" element={<Library />} />
                        <Route
                            path="secao-restrita"
                            element={<RestrictedLibrary />}
                        />
                    </Route>
                    <Route path="ampulhetas" element={<Hourglasses />} />
                    <Route path="relicario" element={<Reliquary />} />
                </Route>
            </Routes>
        </AnimatePresence>
    );
};

export default AppRoutes;
