import { Pagination } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { getPageSlice } from "utils/getPageSlice";
import { getPageSum } from "utils/getPageSum";
import Bookshelf from "components/Bookshelf";
import Container from "./styles";
import MainPassword from "components/MainPassword";
import usePaginatedEl from "hooks/usePaginatedEl";

const LibraryContainer = ({
    books,
    theme = "light",
    locked = false,
    setPassword,
}) => {
    const [page, setPage] = useState(1);
    const [pagesAmount, setPageAmount] = useState(0);
    const booksLength = books?.length ?? 0;
    const bookshelfRef = useRef();
    const libraryRef = useRef();
    const pageSlice = getPageSlice(page, pagesAmount);

    useEffect(() => {
        setPage(1);
    }, [books]);

    usePaginatedEl(libraryRef, bookshelfRef, 55, 220, setPageAmount, 7, locked);

    return (
        <Container ref={libraryRef} theme={theme}>
            {locked || (
                <div className="bookshelf-wrapper">
                    <Bookshelf
                        bookshelfRef={bookshelfRef}
                        books={books?.slice(...pageSlice)}
                        theme={theme}
                    />
                    <Pagination
                        count={getPageSum(booksLength, pagesAmount)}
                        page={page}
                        variant="outlined"
                        onChange={(event, page) => setPage(page)}
                        size="small"
                    />
                </div>
            )}
            {locked && <MainPassword setPassword={setPassword} />}
        </Container>
    );
};

export default LibraryContainer;
