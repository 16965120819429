const fade = (duration = 0.5, delay = 0) => ({
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 1,
        transition: {
            duration,
            delay,
        },
    },
    exit: {
        opacity: 0,
    },
});

export default fade;
