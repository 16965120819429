import { Box, Stack, Typography } from "@mui/material";
import StrokedText from "components/StrokedText";
import { darken } from "polished";

import absoluteCentered from "styles/absoluteCentered";

const HousePoints = ({
    name,
    icon,
    color,
    slogan,
    points,
    align = "flex-start",
}) => {
    return (
        <Stack
            direction="row"
            justifyContent={align}
            alignItems="center"
            spacing={1}
        >
            <Box position="relative">
                <Box
                    position="relative"
                    width={{ md: "7vw", sm: "11vw", xs: "20vw" }}
                    height={{ md: "7vw", sm: "11vw", xs: "20vw" }}
                    sx={{
                        bgcolor: color,
                        clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
                        "&::before": {
                            ...absoluteCentered,
                            width: 1,
                            height: 1,
                            background: `url(${icon}) center/250%`,
                            opacity: 0.4,
                            content: '"',
                        },
                    }}
                >
                    <StrokedText
                        strokeColor={darken(0.4, color)}
                        sx={{
                            ...absoluteCentered,
                            fontFamily: "Impact",
                            fontSize: { md: "2.5vw", sm: "4.5vw", xs: "7vw" },
                            color: "#fff",
                        }}
                    >
                        {points}
                    </StrokedText>
                </Box>
            </Box>
            <Stack display="flex">
                <Typography
                    fontFamily="Impact"
                    fontSize={{ md: "2.5vw", sm: "4.5vw", xs: "6vw" }}
                    color="#fff"
                    textTransform="uppercase"
                    whiteSpace="nowrap"
                >
                    {name}
                </Typography>
                <Typography
                    fontFamily="Miss Fajardose"
                    fontSize={{ md: "5vw", sm: "7vw", xs: "9.5vw" }}
                    color={color}
                    mt="-4.7vw"
                >
                    {slogan}
                </Typography>
            </Stack>
        </Stack>
    );
};

export default HousePoints;
