import styled from "styled-components";

const delay = "0.5s";
const speed = "0.7s";

const Container = styled.div`
    .js-perspective {
        filter: drop-shadow(10px 10px 10px rgb(0, 0, 0, 0.5));
        transition: 0.2s;
        width: 14%;
    }

    .js-perspective:first-of-type {
        transform: translateX(283px) translateY(-89px) rotate(0);
        animation: ${speed} forwards ${delay} card1;
    }

    @keyframes card1 {
        from {
            transform: translateX(283px) translateY(-89px) rotate(0);
        }
        to {
            transform: rotate(-15deg);
        }
    }

    .js-perspective:nth-of-type(2) {
        transform: translateX(140px) translateY(-90px) rotate(0);
        animation: ${speed} forwards ${delay} card2;
    }

    @keyframes card2 {
        from {
            transform: translateX(142px) translateY(-90px) rotate(0);
        }
        to {
            transform: translateY(-65px) rotate(-10deg);
        }
    }

    .js-perspective:nth-of-type(3) {
        transform: translateY(-90px);
    }

    .js-perspective:nth-of-type(4) {
        transform: translateX(-142px) translateY(-90px) rotate(0);
        animation: ${speed} forwards ${delay} card4;
    }

    @keyframes card4 {
        from {
            transform: translateX(-142px) translateY(-90px) rotate(0);
        }
        to {
            transform: translateY(-65px) rotate(10deg);
        }
    }

    .js-perspective:last-of-type {
        transform: translateX(-283px) translateY(-89px) rotate(0);
        animation: ${speed} forwards ${delay} card5;
    }

    @keyframes card5 {
        from {
            transform: translateX(-283px) translateY(-89px) rotate(0);
        }
        to {
            transform: rotate(15deg);
        }
    }

    .js-perspective:hover {
        /* transform: scale(1.1); */
        filter: drop-shadow(15px 15px 10px rgb(0, 0, 0, 0.7));
    }

    .perspective-card-wrap {
        perspective: 1000px;
        perspective-origin: center;
        display: inline-block;
        width: 100%;
    }

    .perspective-card {
        width: 100%;
        height: 300px;
        color: white;
        padding: 0.5rem;
        text-transform: uppercase;
        font-family: "open sans";
        text-align: center;
        /* box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15); */

        /* necessary for effect */
        position: relative;
        transition: none;
        backface-visibility: hidden;
        transform: rotateX(0deg) rotateY(0deg);
        transform-style: preserve-3d;
        will-change: transform;
    }

    .octatitle {
        position: absolute;
        left: 50%;
        top: calc(50% + 4vw);
        transform: translateX(-50%) translateZ(40px);
    }
`;

export default Container;
