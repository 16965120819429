import { getRandomIntInc, getRandomTrue } from "richierich";

import Anchor from "components/Anchor";
import Container from "./styles";

const Book = ({ name, url, theme = "light" }) => {
    name = name?.split(/ +[-:] +/);

    const bookEl = (
        <div
            className={`book book-${getRandomIntInc(
                1,
                13
            )} title-${getRandomIntInc(1, 6)}`}
        >
            <div className="book-title-wrapper">
                <div className="book-title">
                    <h2 className="book-title-first">{name?.[0]}</h2>
                    {name?.length > 1 ? (
                        <h3 className="book-title-second">{name?.[1]}</h3>
                    ) : (
                        false
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <Container theme={theme}>
            <Anchor to={url} external target="_blank">
                {getRandomTrue(3) ? (
                    <div className="book-tilted">{bookEl}</div>
                ) : (
                    bookEl
                )}
            </Anchor>
        </Container>
    );
};

export default Book;
