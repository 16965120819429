import styled from "styled-components";

const themes = {
    dark: {
        paginationBgSelected: "#4a5ea5",
        paginationBgNotSelected: "#5b70bf",
        paginationColor: "#fff",
    },
    light: {
        paginationBgSelected: "#371a0d",
        paginationBgNotSelected: "#210803",
        paginationColor: "#cbb5b5",
    },
};

const Container = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 80%;
    box-sizing: border-box;

    & > .bookshelf-wrapper {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .MuiPagination-root {
        margin-top: -29px;

        & button {
            min-width: 22px;
            height: 22px;
        }

        & .Mui-selected {
            background-color: ${({ theme }) =>
                themes[theme].paginationBgSelected} !important;
            color: ${({ theme }) => themes[theme].paginationColor};
        }

        & ul > li > button:not(.Mui-selected) {
            background-color: ${({ theme }) =>
                themes[theme].paginationBgNotSelected} !important;
            color: ${({ theme }) => themes[theme].paginationColor};
        }

        & ul {
            justify-content: center;
        }
    }
`;

export default Container;
