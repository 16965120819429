import { Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect } from "react";

import Anchor from "components/Anchor";
import Container from "./styles";
import OctaTitle from "components/OctaTitle";
import PerspectiveHover from "classes/PerspectiveHover";
import absoluteCentered from "styles/absoluteCentered";
import expertisesCard from "images/skills/expertises-skill-card.png";
import fade from "animations/fade";
import halfBloodCard from "images/skills/halfblood-card.png";
import illnessesCard from "images/skills/illnesses-card.png";
import nativeSkillCard from "images/skills/native-skill-card.png";
import nonNativeSkillCard from "images/skills/non-native-skill-card.png";

const SkillCategories = () => {
    const categories = [
        {
            name: "Mestiçagens",
            card: halfBloodCard,
        },
        {
            name: "Perícias",
            card: expertisesCard,
        },
        {
            name: "Dons",
            card: nativeSkillCard,
        },
        {
            name: "Maestrias",
            card: nonNativeSkillCard,
        },
        {
            name: "Enfermidades",
            card: illnessesCard,
        },
    ];

    useEffect(() => {
        new PerspectiveHover(".js-perspective-card", ".js-perspective", 8);
    }, []);

    return (
        <Container>
            <Stack
                component={motion.div}
                direction="row"
                justifyContent="center"
                gap="7%"
                width={0.8}
                sx={{ ...absoluteCentered, top: "45%" }}
                {...fade()}
            >
                {categories.map(({ name, card }) => (
                    <div className="js-perspective">
                        <Anchor to={name.toLowerCase()}>
                            <div className="perspective-card-wrap">
                                <div
                                    className="js-perspective-card perspective-card"
                                    style={{
                                        background: `url(${card}) center/contain no-repeat`,
                                    }}
                                >
                                    <OctaTitle color="#272b57">
                                        {name}
                                    </OctaTitle>
                                </div>
                            </div>
                        </Anchor>
                    </div>
                ))}
            </Stack>
        </Container>
    );
};

export default SkillCategories;
