import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import Container from "./styles";
import FramedBox from "components/FramedBox";
import Footer from "components/Footer";
import Header from "components/Header";
import absoluteCentered from "styles/absoluteCentered";

const AppLayout = (props) => {
    const bg = useSelector((state) => state.layout.bg);
    const borderWidth = 55;
    const bgOverlay = useSelector((state) => state.layout.bgOverlay);
    const bgGradient = useSelector((state) => state.layout.bgGradient);
    const hasStars = useSelector((state) => state.layout.hasStars);
    const px = "60px";
    const py = "40px";

    return (
        <Container>
            <Box
                width="100%"
                height="100%"
                overflow="hidden"
                sx={{
                    position: "absolute",
                    background: `${
                        bgGradient
                            ? "linear-gradient(180deg, rgb(0, 0, 0, 60%), transparent 20%, transparent), "
                            : ""
                    }url(${bg}) center/cover no-repeat`,
                    "::before": {
                        ...absoluteCentered,
                        width: "100vw",
                        height: "100vh",
                        bgcolor: bgOverlay ?? "transparent",
                        transition: "background 1s",
                        content: '""',
                    },
                }}
            >
                {hasStars && (
                    <>
                        <div id="stars"></div>
                        <div id="stars2"></div>
                        <div id="stars3"></div>
                    </>
                )}
            </Box>
            <Stack
                spacing={1}
                justifyContent="center"
                alignItems="center"
                width={`calc(100% - ${px})`}
                height={`calc(100% - ${py})`}
                overflow="hidden"
                sx={{ ...absoluteCentered }}
            >
                <Header />
                <FramedBox
                    width={1}
                    height={1}
                    boxSizing="border-box"
                    borderWidth={borderWidth}
                >
                    <Outlet />
                </FramedBox>
                <Footer />
            </Stack>
        </Container>
    );
};

export default AppLayout;
