import { Stack, Typography } from "@mui/material";
import { toHTML } from "discord-markdown";
import SimpleBar from "simplebar-react";

import OctaTitle from "components/OctaTitle";
import horDiv from "images/skills/hordiv1-white.png";

const AcquisitiveInfo = ({
    name,
    description,
    price,
    units,
    disableDiv = false,
    disablePrice = false,
    disableUnits = false,
    sx,
    ...props
}) => {
    return (
        <Stack
            spacing={2}
            justifyContent="center"
            alignItems="flex-end"
            p={3}
            sx={{
                ...sx,
                color: "white",
            }}
            {...props}
        >
            <h1
                style={{
                    margin: "0",
                    fontSize: "2rem",
                    fontFamily: "Hero King",
                    textShadow: "rgba(255,255,255,0.5) 0px 0px 7px",
                }}
            >
                {name?.normalize("NFD").replace(/\p{Diacritic}/gu, "")}
            </h1>
            {disableDiv || (
                <img
                    src={horDiv}
                    alt="Divisor"
                    style={{
                        width: "40%",
                        margin: "0",
                        filter: "drop-shadow(0px 0px 7px rgba(255,255,255,0.5))",
                    }}
                />
            )}
            <SimpleBar
                style={{
                    width: "100%",
                    height: "fit-content",
                    maxHeight: "10vw",
                    textShadow: "rgba(255,255,255,0.5) 0px 0px 7px",
                }}
            >
                <p
                    style={{
                        padding: 0,
                        margin: 0,
                        fontSize: "0.9rem",
                        textAlign: "right",
                    }}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: description
                                ? toHTML(description)?.replace(/\\n/g, "<br />")
                                : "",
                        }}
                    />
                </p>
            </SimpleBar>
            <Stack gap="5px" alignItems="center">
                {disablePrice ||
                    (price && (
                        <OctaTitle
                            fontSize="1rem"
                            color="#132d4d"
                            style={{
                                textShadow: "rgba(255,255,255,0.5) 0px 0px 7px",
                            }}
                        >
                            Preço: <strong>{price} MP</strong>
                        </OctaTitle>
                    ))}
                {disableUnits ||
                    (units && (
                        <Typography
                            m="0 auto"
                            fontSize="0.54rem"
                            color="#ff4949"
                            textAlign="center"
                            whiteSpace="nowrap"
                            sx={{
                                textShadow: "1px 1px 0 #732f2f",
                            }}
                        >
                            {/[0-9]+/.test(units)
                                ? `${units} UNIDADE${
                                      +units > 1 ? "S" : ""
                                  } DISPONÍVE${+units > 1 ? "IS" : "L"}`
                                : units?.toUpperCase()}
                        </Typography>
                    ))}
            </Stack>
        </Stack>
    );
};

export default AcquisitiveInfo;
