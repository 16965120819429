import styled from "styled-components";

import mask from "images/reliquary/image-mask.png";

const Container = styled.div`
    width: 100%;
    height: 100%;

    .relic__image {
        width: 25vw;
        height: 25vw;
        mask: url(${mask}) center/cover no-repeat;
    }

    .relic__div {
        position: relative;
        height: 20vw;
        margin-left: -4vw;
        z-index: 3;
        filter: drop-shadow(3px 3px 2px rgb(0, 0, 0, 0.8));
    }
`;

export default Container;
