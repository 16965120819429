import { Link } from "react-router-dom";

const Anchor = ({ children, external, target, to }) => {
    return external ? (
        <a href={to} target={target}>
            {children}
        </a>
    ) : (
        <Link to={to} target={target}>
            {children}
        </Link>
    );
};

export default Anchor;
