import styled from "styled-components";

const Container = styled.div`
    width: fit-content;

    .octatitle,
    .octatitle span {
        clip-path: polygon(
            9px 0%,
            calc(100% - 9px) 0%,
            100% 30%,
            100% 70%,
            calc(100% - 9px) 100%,
            9px 100%,
            0% 70%,
            0% 30%
        );
    }

    .octatitle {
        position: relative;
        width: fit-content;
        height: fit-content;
        padding: 1px;
        background: linear-gradient(
            90deg,
            #ffab61,
            #fff7d5 23%,
            #ffc387 52%,
            #fff2c9 75%,
            #fff2d1 82%,
            #ffe7af 92%,
            #ffbf7a 100%
        );
        text-align: center;
        transform: translateZ(40px);
        -moz-osx-font-smoothing: grayscale;
    }

    .octatitle span {
        position: relative;
        display: inline-block;
        padding: 7px;
        background-color: ${({ color }) => color ?? "#132d4d"};
        font-size: ${({ fontSize }) => fontSize ?? "0.7rem"};
        color: #fff;
        white-space: nowrap;
    }
`;

export default Container;
