import { isEmpty } from "richierich";
import { useEffect, useState } from "react";

import { getDocs } from "db";

const useBooks = (secret = false) => {
    const [books, setBooks] = useState([]);

    useEffect(() => {
        (async () => {
            let booksDocs = await getDocs({
                collectionName: "books",
                params: [["secret", "==", secret]],
            });
            if (isEmpty(booksDocs)) {
                setBooks([]);
                return;
            }
            setBooks(booksDocs);
        })();
    }, [secret]);

    return books;
};

export default useBooks;
