import { Box } from "@mui/material";

import border1 from "images/border1.png";
import border2 from "images/border2.png";

const FramedBox = ({
    borderWidth = 5,
    borderType = 1,
    children,
    sx,
    ...props
}) => {
    let border = "";
    let borderSlice = 1;

    switch (borderType) {
        case 1:
            border = border1;
            borderSlice = 171;
            break;
        case 2:
            border = border2;
            borderSlice = 384;
            break;
        default:
            border = border1;
            borderSlice = 171;
    }

    return (
        <Box
            sx={{
                borderStyle: "solid",
                borderWidth,
                borderImage: `url(${border}) ${borderSlice}`,
                ...sx,
            }}
            {...props}
        >
            {children}
        </Box>
    );
};

export default FramedBox;
