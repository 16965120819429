import { Box } from "@mui/material";
import DividedStack from "components/DividedStack";
import HousePoints from "components/HousePoints";

const HousePointsNav = ({
    items,
    spacing = 8,
    divider = false,
    align = "flex-start",
}) => {
    return (
        <Box
            width={{ sm: "350px", xs: 1 }}
            sx={{ filter: "drop-shadow(5px 5px 3px rgb(0, 0, 0, 0.4))" }}
        >
            <DividedStack
                spacing={spacing}
                alignItems={align}
                divider={divider}
                divStyle={{ width: { sm: "20vw", xs: "50vw" } }}
            >
                {items?.map(({ name, icon, color2, slogan, points }) => (
                    <HousePoints
                        name={name}
                        icon={icon}
                        color={color2}
                        slogan={slogan}
                        points={points}
                        align={align}
                    />
                ))}
            </DividedStack>
        </Box>
    );
};

export default HousePointsNav;
