import styled from "styled-components";

import mainInputTop from "images/main-input-top.png";
import mainInputBottom from "images/main-input-bottom.png";

const Container = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    &::before,
    &::after,
    .MuiFormControl-root {
        width: 300px;
    }

    &::before,
    &::after {
        display: block;
        height: 100px;
        margin: 0 auto;
        content: "";
    }

    &::before {
        background: url(${mainInputTop}) bottom/contain no-repeat;
    }

    &::after {
        background: url(${mainInputBottom}) top/contain no-repeat;
    }

    .MuiFormControl-root {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin: 25px auto;
    }

    .MuiOutlinedInput-root {
        position: relative;
    }

    .MuiOutlinedInput-root::before {
        position: absolute;
        inset: 0;
        border-radius: 50px;
        padding: 2px;
        background: linear-gradient(
            90deg,
            #ffab61,
            #fff7d5 23%,
            #ffc387 52%,
            #fff2c9 75%,
            #fff2d1 82%,
            #ffe7af 92%,
            #ffbf7a 100%
        );
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        content: "";
    }

    .MuiFormLabel-root.Mui-focused,
    .MuiFormLabel-root.MuiFormLabel-filled {
        color: #fff !important;
        transform: translate(14px, -19px) scale(0.75) !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none;
    }

    .MuiInputLabel-root,
    .MuiInputBase-input,
    .MuiInputAdornment-root .MuiIconButton-root {
        color: #fff;
    }
`;

export default Container;
