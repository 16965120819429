import styled from "styled-components";

const random = (max) => Math.random() * max;
const shadowsColor = "rgba(242, 216, 197, 0.6)";

const multipleBoxShadow = (n) => {
    let value = `${random(2000)}px ${random(2000)}px ${shadowsColor}`;
    for (var i = 2; i <= n; ++i) {
        value = `${value} , ${random(2000)}px ${random(
            2000
        )}px ${shadowsColor}`;
    }
    return value;
};

const shadowsSmall = multipleBoxShadow(700);
const shadowsMedium = multipleBoxShadow(200);
const shadowsBig = multipleBoxShadow(100);

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;

    #stars {
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: ${shadowsSmall};
        animation: animStar 50s linear infinite;

        &:after {
            content: " ";
            position: absolute;
            top: 2000px;
            width: 1px;
            height: 1px;
            background: transparent;
            box-shadow: ${shadowsSmall};
        }
    }

    #stars2 {
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: ${shadowsMedium};
        animation: animStar 100s linear infinite;

        &:after {
            content: " ";
            position: absolute;
            top: 2000px;
            width: 2px;
            height: 2px;
            background: transparent;
            box-shadow: ${shadowsMedium};
        }
    }

    #stars3 {
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: ${shadowsBig};
        animation: animStar 150s linear infinite;

        &:after {
            content: " ";
            position: absolute;
            top: 2000px;
            width: 3px;
            height: 3px;
            background: transparent;
            box-shadow: ${shadowsBig};
        }
    }

    @keyframes animStar {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-2000px);
        }
    }
`;

export default Container;
