import { Stack } from "@mui/material";

import Anchor from "components/Anchor";
import Container from "./styles";

const Footer = () => {
    return (
        <Container>
            <Stack spacing={1} direction="row" divider={<span>|</span>}>
                <span>
                    MADE{" "}
                    <Anchor to="https://byseidr.com" external>
                        BY SEIDR
                    </Anchor>
                </span>
                <span>
                    SEDIADO NO{" "}
                    <Anchor to="https://ironhotel.org" external>
                        IRON HOTEL
                    </Anchor>
                </span>
                <span>COPYRIGHT© 2024</span>
            </Stack>
        </Container>
    );
};

export default Footer;
