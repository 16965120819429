import { isEmpty } from "richierich";
import { useEffect, useState } from "react";

import { getDocs } from "db";

const useSkills = (category) => {
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        (async () => {
            let skillsDocs = await getDocs({
                collectionName: "acquisitives",
                params: [["category", "==", category]],
            });
            if (isEmpty(skillsDocs)) {
                setSkills([]);
                return;
            }
            setSkills(skillsDocs);
        })();
    }, [category]);

    return skills;
};

export default useSkills;
