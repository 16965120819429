import Container from "./styles";

const OctaTitle = ({ fontSize, color, children, ...props }) => {
    return (
        <Container fontSize={fontSize} color={color}>
            <div className="octatitle" {...props}>
                <span>{children}</span>
            </div>
        </Container>
    );
};

export default OctaTitle;
