import { createGlobalStyle } from "styled-components";

import heroKingTtf from "../fonts/HeroKing/HeroKing.ttf";
import satisfyTtf from "../fonts/Satisfy/Satisfy.ttf";
import southamDemoTtf from "../fonts/SouthamDemo/SouthamDemo.ttf";
import lovelyHomeTtf from "../fonts/LovelyHome/LovelyHome.ttf";

export default createGlobalStyle`
    @font-face {
        font-family: 'Hero King';
        src: url(${heroKingTtf}) format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Lovely Home';
        src: url(${lovelyHomeTtf}) format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Satisfy';
        src: url(${satisfyTtf}) format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Southam Demo';
        src: url(${southamDemoTtf}) format('truetype');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    /* * {
        padding: 0;
        margin: 0;
        outline: none;
        box-sizing: border-box;
    } */

    #root {
        height: 100vh;
        overflow: hidden;
    }

    body {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        background-color: #fff;
    }

    a {
        text-decoration: none;
    }

    li {
        list-style: none;
    }
`;
