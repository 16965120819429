import { Box } from "@mui/material";

import Anchor from "components/Anchor";
import Container from "./styles";
import DividedStack from "components/DividedStack";

const BannerNav = ({ items, divider = false }) => {
    return (
        <Container>
            <DividedStack divider={divider}>
                {items.map(({ title, titleImg, icon, yPos, to }) => (
                    <Anchor key={title} to={to}>
                        <div className="title">
                            {titleImg ? (
                                <img src={titleImg} alt={title} />
                            ) : (
                                <span>{title}</span>
                            )}
                        </div>
                        <Box p={1} border="1px solid #ddaf5d">
                            <Box
                                width={1}
                                height="15vh"
                                sx={{
                                    background: `url(${icon}) ${yPos}/cover no-repeat`,
                                }}
                            />
                        </Box>
                    </Anchor>
                ))}
            </DividedStack>
        </Container>
    );
};

export default BannerNav;
