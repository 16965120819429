import styled from "styled-components";

const opacity = 0.8;
const scale = 0.9;

const Container = styled.div`
    .circle {
        display: none;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: scale(${scale}) translate(-50%, -50%);
    }

    .circle-intro {
        opacity: 0;
        animation: 0.7s forwards linear circleIntro;
    }

    .circle-outro {
        opacity: ${opacity};
        animation: 0.7s forwards linear circleOutro;
    }

    @keyframes circleIntro {
        from {
            opacity: 0;
            transform: scale(${scale}) translate(-50%, -50%) rotate(0deg);
        }

        to {
            opacity: ${opacity};
            transform: scale(1) translate(-50%, -50%) rotate(360deg);
        }
    }

    @keyframes circleOutro {
        from {
            opacity: ${opacity};
            transform: scale(1) translate(-50%, -50%) rotate(0deg);
        }

        to {
            opacity: 0;
            transform: scale(${scale}) translate(-50%, -50%) rotate(360deg);
        }
    }

    .selector {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: ${({ dialIconSize }) => dialIconSize ?? "20vw"};
        height: ${({ dialIconSize }) => dialIconSize ?? "20vw"};
    }

    .selector > img {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        padding: ${({ selectorPadding }) => selectorPadding ?? "10px"};
        cursor: pointer;
    }

    .selector > img {
        padding: ${({ spacing }) => spacing ?? "10px"};
        transition: all 0.6s;

        &:hover {
            filter: drop-shadow(0px 0px 18px #61a6e6);
        }
    }

    .selector ul {
        position: absolute;
        list-style: none;
        padding: 0;
        margin: 0;
        top: -${({ dialSize }) => dialSize ?? "10px"};
        right: -${({ dialSize }) => dialSize ?? "10px"};
        bottom: -${({ dialSize }) => dialSize ?? "10px"};
        left: -${({ dialSize }) => dialSize ?? "10px"};
    }

    .selector li {
        position: absolute;
        width: 0;
        height: 100%;
        margin: 0 50%;
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
        transition: all 0.8s ease-in-out;
    }

    .selector li .option {
        position: absolute;
        left: 50%;
        bottom: 100%;
        width: 0;
        height: 0;
        margin-left: 0;
        border-radius: 50%;
        overflow: hidden;
        /* cursor: pointer; */
        transition: all 0.8s ease-in-out, color 0.1s, background 0.1s;
    }

    .selector.open li .option {
        width: ${({ iconSize }) => iconSize ?? "5vw"};
        height: ${({ iconSize }) => iconSize ?? "5vw"};
        margin-left: -40px;
    }
`;

export default Container;
