import { Typography } from "@mui/material";

const StrokedText = ({
    children,
    strokeSize = "1px",
    strokeColor = "black",
    sx,
    ...props
}) => {
    return (
        <Typography
            sx={{
                textShadow: `-${strokeSize} -${strokeSize} 0 ${strokeColor},
                            ${strokeSize} -${strokeSize} 0 ${strokeColor},
                            -${strokeSize} ${strokeSize} 0 ${strokeColor},
                            ${strokeSize} ${strokeSize} 0 ${strokeColor}`,
                ...sx,
            }}
            {...props}
        >
            {children}
        </Typography>
    );
};

export default StrokedText;
