import bg from "images/bg.png";

const initialState = {
    bg,
    bgOverlay: "rgb(4, 17, 43, .75)",
    bgGradient: false,
    hasStars: true,
    isRadialOpen: false,
};

const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case "layout/hasBgGradient":
            return { ...state, bgGradient: action.payload };
        case "layout/hasStars":
            return { ...state, hasStars: action.payload };
        case "layout/isRadialOpen":
            return { ...state, isRadialOpen: action.payload };
        case "layout/removeBgOverlay":
            return { ...state, bgOverlay: null };
        case "layout/resetBg":
            return { ...state, bg: initialState.bg };
        case "layout/resetBgOverlay":
            return { ...state, bgOverlay: initialState.bgOverlay };
        case "layout/setBg":
            return { ...state, bg: action.payload };
        case "layout/setBgOverlay":
            return { ...state, bgOverlay: action.payload };
        default:
            return state;
    }
};

export default layoutReducer;
