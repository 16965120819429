import { Box } from "@mui/material";

import absoluteCentered from "styles/absoluteCentered";

const AbsoluteImage = ({ url, width = 1, height = 1, sx, ...props }) => {
    return (
        <Box
            width={width}
            height={height}
            sx={{
                ...absoluteCentered,
                background: `url(${url}) center/contain no-repeat`,
                ...sx,
            }}
            {...props}
        />
    );
};

export default AbsoluteImage;
