import { getRandomEl } from "richierich";

import symbol1 from "images/reliquary/symbol1.png";
import symbol2 from "images/reliquary/symbol2.png";
import symbol3 from "images/reliquary/symbol3.png";
import symbol4 from "images/reliquary/symbol4.png";
import symbol5 from "images/reliquary/symbol5.png";
import symbol6 from "images/reliquary/symbol6.png";
import symbol7 from "images/reliquary/symbol7.png";
import symbol8 from "images/reliquary/symbol8.png";
import symbol9 from "images/reliquary/symbol9.png";
import symbol10 from "images/reliquary/symbol10.png";
import symbol11 from "images/reliquary/symbol11.png";
import symbol12 from "images/reliquary/symbol12.png";
import symbol13 from "images/reliquary/symbol13.png";
import symbol14 from "images/reliquary/symbol14.png";
import symbol15 from "images/reliquary/symbol15.png";
import symbol16 from "images/reliquary/symbol16.png";

const symbols = [
    symbol1,
    symbol2,
    symbol3,
    symbol4,
    symbol5,
    symbol6,
    symbol7,
    symbol8,
    symbol9,
    symbol10,
    symbol11,
    symbol12,
    symbol13,
    symbol14,
    symbol15,
    symbol16,
];

const getRandomSymbol = () => getRandomEl(symbols);

export default getRandomSymbol;
