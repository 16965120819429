import styled from "styled-components";

const Container = styled.div`
    font-size: 0.7vw;
    font-family: "El Messiri";

    &,
    a {
        color: #ddaf5d;
    }

    a {
        text-decoration: underline;
        text-underline-offset: 3px;
    }
`;

export default Container;
