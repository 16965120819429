import { Box, Modal } from "@mui/material";

import AcquisitiveInfo from "components/AcquisitiveInfo";
import absoluteCentered from "styles/absoluteCentered";

const SkillModal = ({
    image,
    name,
    description,
    price,
    units,
    open,
    handleClose,
}) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    ...absoluteCentered,
                    width: 0.5,
                    height: 0.4,
                    p: 4,
                    borderRadius: "20px",
                    bgcolor: "rgb(31, 46, 75, .85)",
                    filter: "drop-shadow(10px 10px 10px rgb(0, 0, 0, 0.5))",
                    overflow: "hidden",
                }}
            >
                <Box
                    position="absolute"
                    left="-30%"
                    top={0}
                    width={1}
                    height={1}
                    sx={{
                        background: `url(${image}) center/cover no-repeat`,
                        mask: "linear-gradient(104deg, black, black 40%, transparent 80%, transparent)",
                    }}
                ></Box>
                <AcquisitiveInfo
                    name={name}
                    description={description}
                    price={price}
                    units={units}
                    position="absolute"
                    right={0}
                    top="50%"
                    width={0.6}
                    height={0.8}
                    sx={{ transform: "translateY(-50%)" }}
                />
            </Box>
        </Modal>
    );
};

export default SkillModal;
