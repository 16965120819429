import { combineReducers } from "redux";

import layoutReducer from "./layoutReducer";
import skillsReducer from "./skillsReducer";

const allReducers = combineReducers({
    layout: layoutReducer,
    skills: skillsReducer,
});

export default allReducers;
