import { useDispatch } from "react-redux";
import { useEffect } from "react";
import $ from "jquery";

import Container from "./styles";
import Relic from "components/Relic";
import Slide from "components/Slide";
import bg from "images/reliquary/bg.jpg";
import getRandomSymbol from "utils/getRandomSymbol";
import useRelics from "hooks/useRelics";

const Reliquary = () => {
    const dispatch = useDispatch();
    const relics = useRelics();

    useEffect(() => {
        dispatch({ type: "layout/setBg", payload: bg });
        dispatch({ type: "layout/hasBgGradient", payload: false });
        dispatch({ type: "layout/removeBgOverlay" });
    }, [dispatch]);

    useEffect(() => {
        $(".slick-dots li button").each(function () {
            $(this)?.css({ backgroundImage: `url(${getRandomSymbol()})` });
        });
    }, []);

    return (
        <Container>
            <Slide
                arrows
                infinite
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                fade
                cssEase="linear"
                focusOnSelect
                centerMode
            >
                {relics?.map(
                    ({ name, image, description, price, units }, i) => (
                        <Relic
                            key={i}
                            image={image}
                            name={name}
                            description={description}
                            price={price}
                            units={units}
                        />
                    )
                )}
            </Slide>
        </Container>
    );
};

export default Reliquary;
