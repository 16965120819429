import legiliNativa from "images/skills/OIG3.png";
import metamorfo from "images/skills/OIG1.png";
import clarivi from "images/skills/OIG4.png";
import zooglo from "images/skills/OIG2.png";
import ofidio from "images/skills/OIG4 (1).png";

const initialState = {
    dons: [
        { name: "Legilimência Nativa", image: legiliNativa },
        { name: "Metamorfomagia", image: metamorfo },
        { name: "Clarividência", image: clarivi },
        { name: "Zooglossia", image: zooglo },
        { name: "Ofidioglossia", image: ofidio },
    ],
    maestrias: [
        { name: "Arte das Trevas" },
        { name: "Avaração" },
        { name: "Conjuração e Transformação" },
        { name: "Feitiços" },
        { name: "Herbologia" },
        { name: "Legilimência Não-Nativa" },
        { name: "Magia Elemental" },
        { name: "Medicina Mágica" },
        { name: "Mediunidade" },
        { name: "Métodos Divinatórios" },
        { name: "Oclumência" },
        { name: "Poções" },
    ],
    mesticagens: [
        { name: "Meio-Veela" },
        { name: "Meio-Duende" },
        { name: "Meio-Vampiro" },
        { name: "Meio-Caipora" },
        { name: "Meio-Gigante" },
    ],
};

const skillsReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default skillsReducer;
