import styled from "styled-components";

const Container = styled.div`
    /* Extra small devices (phones, 600px and down) */
    @media (orientation: portrait) and (max-width: 600px) {
        .hourglass-container {
            gap: 10%;
            width: 68%;
            margin-top: 8.2vw;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media (orientation: portrait) and (min-width: 600px) {
        .hourglass-container {
            gap: 10%;
            width: 68%;
            margin-top: 8.2vw;
        }
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media (orientation: landscape) and (min-width: 768px) {
        .hourglass-container {
            gap: 7%;
            height: 64%;
            margin-top: 8vh;
        }
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media (orientation: landscape) and (min-width: 992px) {
        .hourglass-container {
            gap: 7%;
            height: 64%;
            margin-top: 8vh;
        }
    }

    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media (orientation: landscape) and (min-width: 1200px) {
        .hourglass-container {
            gap: 7%;
            height: 64%;
            margin-top: 8vh;
        }
    }
`;

export default Container;
