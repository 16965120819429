import Container from "./styles";

const HourglassContent = ({ width, radius, position, color, started }) => {
    return (
        <Container
            width={width}
            radius={radius}
            position={position}
            color={color}
        >
            <div className="hourglass-wrapper">
                <div className={`hourglass${started ? " visible" : ""}`}>
                    <div className="hourglass-part hourglass-part-B">
                        <div className="sand-wrapper">
                            <div className="sand-inner">
                                <div className="sand"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <label for="hourglass1"></label>
                <label for="hourglass2"></label>
                <div className="change-blocker"></div>
            </div>
        </Container>
    );
};

export default HourglassContent;
