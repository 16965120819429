import { Box, Stack } from "@mui/material";

import { getPerc } from "utils/getPerc";
import AbsoluteImage from "components/AbsoluteImage";
import Container from "./styles";
import HourglassContent from "components/HourglassContent";
import absoluteCentered from "styles/absoluteCentered";
import hourglasses1 from "images/hourglasses/hourglasses1.png";
import hourglasses2 from "images/hourglasses/hourglasses2.png";
import hourglasses3 from "images/hourglasses/hourglasses3.gif";

const Hourglass = ({
    houses,
    width = 1,
    height = 1,
    maxPoints,
    started,
    sx,
    ...props
}) => {
    return (
        <Container>
            <Box sx={{ ...sx }} {...props}>
                <AbsoluteImage
                    url={hourglasses1}
                    width={width}
                    height={height}
                />
                <Box
                    width={width}
                    height={height}
                    sx={{
                        ...absoluteCentered,
                        filter: "drop-shadow(0px 0px 15px rgb(0,0,0,.5))",
                    }}
                >
                    <Stack
                        className="hourglass-container"
                        top="19.5%"
                        direction="row"
                        justifyContent="center"
                        ml="1px"
                        sx={{
                            ...absoluteCentered,
                            aspectRatio: "1/0.889",
                        }}
                    >
                        {houses?.map(({ color, points }) => (
                            <HourglassContent
                                width="105px"
                                radius="40px 20px"
                                position={getPerc(points, maxPoints)}
                                color={color}
                                started={started}
                            />
                        ))}
                    </Stack>
                </Box>
                <AbsoluteImage
                    url={hourglasses2}
                    width={width}
                    height={height}
                />
                <AbsoluteImage
                    url={hourglasses3}
                    width={width}
                    height={height}
                />
            </Box>
        </Container>
    );
};

export default Hourglass;
