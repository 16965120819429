import styled from "styled-components";

const Container = styled.div`
    width: 20%;
    height: 100%;

    .title {
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        width: 75%;
        text-align: center;
        z-index: 150;
    }

    .title img {
        width: 100%;
    }

    .title span {
        font-size: 2.5rem;
        line-height: 3rem;
        font-family: "Lovely Home";
    }

    .banners {
        width: 100%;
    }
`;

export default Container;
