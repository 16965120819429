import { useEffect } from "react";
import { useDispatch } from "react-redux";

import LibraryContainer from "components/LibraryContainer";
import bg from "images/library/bg.png";
import useBooks from "hooks/useBooks";

const Library = () => {
    const books = useBooks();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "layout/setBg", payload: bg });
        dispatch({ type: "layout/hasBgGradient", payload: true });
        dispatch({ type: "layout/removeBgOverlay" });
    }, [dispatch]);

    return <LibraryContainer books={books} theme="light" />;
};

export default Library;
