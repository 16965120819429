import { Box, Stack } from "@mui/material";
import AcquisitiveInfo from "components/AcquisitiveInfo";

import Container from "./styles";
import verDiv from "images/reliquary/verdiv1.png";

const Relic = ({ image, name, description, price, units }) => {
    return (
        <Container>
            <Stack
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
            >
                <Box
                    sx={{
                        filter: "drop-shadow(5px 5px 5px rgb(0, 0, 0, 0.4))",
                    }}
                >
                    <Box
                        className="relic__image"
                        sx={{
                            background: `url(${image}) center/cover no-repeat`,
                        }}
                    />
                </Box>
                <img className="relic__div" src={verDiv} alt="Divisor" />
                <AcquisitiveInfo
                    name={name}
                    description={description}
                    price={price}
                    units={units}
                    width={0.4}
                    p={0}
                    pl={3}
                    height="fit-content"
                    disableDiv
                    disablePrice={false}
                    disableUnits={false}
                />
            </Stack>
        </Container>
    );
};

export default Relic;
