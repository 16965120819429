import "simplebar/dist/simplebar.min.css";
import { Box, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { toUpFirst } from "richierich";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";

import Container from "./styles";
import PerspectiveHover from "classes/PerspectiveHover";
import absoluteCentered from "styles/absoluteCentered";
import fade from "animations/fade";
import positionCentered from "styles/positionCentered";
import SkillCard from "components/SkillCard";
import SkillModal from "components/SkillModal";
import useSkills from "hooks/useSkills";

const Skills = () => {
    const [skill, setSkill] = useState({});
    const [skillOpen, setSkillOpen] = useState(false);
    const { category } = useParams();
    const { skill: skillName } = useParams();
    const navigate = useNavigate();
    const skills = useSkills(toUpFirst(decodeURIComponent(category)));

    useEffect(() => {
        new PerspectiveHover(".js-perspective-card", ".js-perspective", 8);
    }, []);

    useEffect(() => {
        setSkillOpen(!!skillName);
        if (skillName)
            setSkill(
                skills.find((s) => s.name === decodeURIComponent(skillName))
            );
    }, [skillName, skills]);

    const handleSkillClose = () => {
        setSkillOpen(false);
        navigate(`/habilidades/${category}`);
    };

    // useEffect(() => {
    //     const handleWheelScroll = (e) => {
    //         console.log("Hey");
    //     };

    //     window.addEventListener("wheel", handleWheelScroll);

    //     return window.removeEventListener("wheel", handleWheelScroll);
    // }, []);

    return (
        <Container>
            <Box
                component={motion.div}
                width={1}
                height={0.9}
                sx={{
                    ...absoluteCentered,
                    mask: "linear-gradient(90deg, transparent, black 5%, black 95%, transparent)",
                }}
                {...fade()}
            >
                <SimpleBar
                    forceVisible="y"
                    autoHide={false}
                    style={{
                        position: "relative",
                        ...positionCentered,
                        width: "100%",
                        height: "70%",
                    }}
                >
                    <Stack
                        position="relative"
                        top="50%"
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap="20px"
                        width="fit-content"
                        height={1}
                        p="0 5%"
                        m="0 auto"
                        sx={{ transform: "translateY(-50%)" }}
                    >
                        {skills.map(({ image, name, description }) => (
                            <SkillCard
                                image={image}
                                name={name}
                                description={description}
                            />
                        ))}
                    </Stack>
                </SimpleBar>
            </Box>
            <SkillModal
                {...skill}
                open={skillOpen}
                handleClose={handleSkillClose}
            />
        </Container>
    );
};

export default Skills;
