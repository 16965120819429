import { Box, Stack } from "@mui/material";
import { isStr } from "richierich";

import Container from "./styles";
import horDiv from "images/hordiv1.png";

const DividedStack = ({
    children,
    divider = false,
    divStyle = {},
    ...props
}) => {
    divider = isStr(divider) ? (
        <Box display="block" sx={divStyle}>
            <img src={divider} alt="Divisória" style={{ width: "100%" }} />
        </Box>
    ) : divider ? (
        <Box display="block">
            <img
                className="divider"
                src={horDiv}
                alt="Divisória"
                style={divStyle}
            />
        </Box>
    ) : (
        false
    );

    return (
        <Container>
            <Stack
                justifyContent="space-between"
                height={1}
                divider={divider}
                {...props}
            >
                {children}
            </Stack>
        </Container>
    );
};

export default DividedStack;
