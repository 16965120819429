import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { Box } from "@mui/material";

const Slide = (props) => {
    return (
        <Box sx={{ ".slick-dots li button:before": { color: "text.primary" } }}>
            <Slider {...props} />
        </Box>
    );
};

export default Slide;
