import { useEffect } from "react";
import { useDispatch } from "react-redux";

import Container from "./styles";
import bg from "images/skills/bg.png";
import { Outlet } from "react-router-dom";

const SkillsLayout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: "layout/setBg", payload: bg });
        dispatch({ type: "layout/hasBgGradient", payload: true });
        dispatch({ type: "layout/removeBgOverlay" });
    }, [dispatch]);

    return (
        <Container>
            <Outlet />
        </Container>
    );
};

export default SkillsLayout;
