import {
    collection,
    getDocs as fbGetDocs,
    getFirestore,
    limit as fbLimit,
    onSnapshot,
    orderBy,
    query as fbQuery,
    where,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { isArr } from "richierich";

const app = initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
});

const db = getFirestore(app);

const getQuery = ({ collectionName, params, limit, orders }) => {
    let collectionRef = collection(db, collectionName);
    const queryArgs = [collectionRef];
    if (params?.length)
        queryArgs.push(...params.map((param) => where(...param)));
    if (limit) queryArgs.push(fbLimit(limit));
    if (orders?.length)
        queryArgs.push(...orders.map((order) => orderBy(...[order].flat())));
    return fbQuery(...queryArgs);
};

export const auth = getAuth;

export const getDoc = async (collectionName, id) => {
    if (!collectionName || !id) return;
    const docs = await getDocs({
        collectionName,
        params: [["id", "==", id]],
    });
    return docs?.[0];
};

export const getDocs = async (query) => {
    if (!query?.collectionName) return;
    query = getQuery(query);
    const snapshot = await fbGetDocs(query);
    return snapshot.docs.map((doc) => ({ ...doc.data() }));
};

export const getDocsBatch = async (collectionName, path, op, value) => {
    const result = [];
    if (!isArr(value)) return result;
    for (let i = 0; i < value.length; i += 10) {
        const subResult = await getDocs({
            collectionName,
            params: [[path, op, value.slice(i, i + 10)]],
        });
        result.push(...subResult);
    }
    return result;
};

export const getRealtimeDocs = async (query, callback) => {
    if (!query?.collectionName || !callback) return;
    query = getQuery(query);
    return onSnapshot(query, async (snapshot) => {
        const docs = snapshot.docs.map((doc) => ({ ...doc.data() }));
        await callback(docs);
    });
};

export const hasDoc = async (query) => {
    if (!query?.collectionName) return;
    query = getQuery(query);
    const snapshot = await fbGetDocs(query);
    return !!snapshot && !snapshot.empty;
};
