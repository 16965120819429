import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import $ from "jquery";

import Container from "./styles";
import { useCallback, useEffect } from "react";

const RadialNav = ({
    selectorIcon,
    items = [],
    iconSize,
    dialIconSize,
    dialSize,
    selectorPadding,
    spacing,
}) => {
    const angleStart = -360;
    const dispatch = useDispatch();
    const isRadialOpen = useSelector((state) => state.layout.isRadialOpen);

    // jquery rotate animation
    const rotate = useCallback(
        (li, d) => {
            $({ d: angleStart }).animate(
                { d: d },
                {
                    step: function (now) {
                        $(li)
                            .css({ transform: "rotate(" + now + "deg)" })
                            .find(".option")
                            .css({ transform: "rotate(" + -now + "deg)" });
                    },
                    duration: 0,
                }
            );
        },
        [angleStart]
    );

    // show / hide the options
    const toggleOptions = useCallback(
        (s) => {
            $(s).toggleClass("open");
            const isOpen = $(s).hasClass("open");
            dispatch({
                type: "layout/isRadialOpen",
                payload: isOpen,
            });
            dispatch({
                type: `layout/${isOpen ? "" : "re"}setBgOverlay`,
                payload: "rgb(89, 11, 44, .75)",
            });
            var li = $(s).find("li");
            var deg = $(s).hasClass("half")
                ? 180 / (li.length - 1)
                : 360 / li.length;
            for (var i = 0; i < li.length; i++) {
                var d = $(s).hasClass("half") ? i * deg - 90 : i * deg;
                isOpen ? rotate(li[i], d) : rotate(li[i], angleStart);
            }
        },
        [angleStart, dispatch, rotate]
    );

    const handleSelectorClick = (e) => {
        const selector = $(e.target).parent();
        toggleOptions(selector);
    };

    useEffect(() => {
        const intro = setTimeout(function () {
            toggleOptions(".selector");
        }, 100); //@ sourceURL=pen.js

        return () => {
            clearTimeout(intro);
        };
    }, [toggleOptions]);

    return (
        <Container
            iconSize={iconSize}
            dialIconSize={dialIconSize}
            dialSize={dialSize}
            selectorPadding={selectorPadding}
            spacing={spacing}
        >
            <svg
                className={`circle ${
                    isRadialOpen ? "circle-intro" : "circle-outro"
                }`}
                viewBox="0 0 120 120"
                version="1.1"
                xmlns="https://www.w3.org/2000/svg"
                style={{ width: "29vw", height: "29vw" }}
            >
                <circle
                    cx="50%"
                    cy="50%"
                    r="50%"
                    fill="transparent"
                    stroke="#ddaf5d"
                    stroke-width="0.3"
                    strokeDasharray="5 10 5"
                />
            </svg>
            <div className="selector">
                <ul>
                    {items.map(({ icon, title }) => (
                        <li key={title}>
                            <img className="option" src={icon} alt={title} />
                        </li>
                    ))}
                </ul>
                <img src={selectorIcon} alt="" onClick={handleSelectorClick} />
            </div>
        </Container>
    );
};

export default RadialNav;
