import { isEmpty } from "richierich";
import { useEffect, useState } from "react";

import { getDocs } from "db";

const useRelics = () => {
    const [relics, setRelics] = useState([]);

    useEffect(() => {
        (async () => {
            let relicsDocs = await getDocs({
                collectionName: "acquisitives",
                params: [["category", "==", "Relíquias"]],
            });
            if (isEmpty(relicsDocs)) {
                setRelics([]);
                return;
            }
            setRelics(relicsDocs);
        })();
    }, []);

    return relics;
};

export default useRelics;
