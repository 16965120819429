import { useEffect } from "react";

import useWindowSize from "hooks/useWindowSize";

const usePaginatedEl = (
    containerRef,
    elRef,
    itemWidth,
    itemHeight,
    setPageAmount,
    xTrim = 0,
    yTrim = 0,
    loaded
) => {
    const windowSize = useWindowSize();

    useEffect(() => {
        const getAttr = (attr) => Number(attr?.replace(/[a-z%]+/, ""));

        const getHeight = (containerStyle, elStyle) => {
            const { height, paddingTop, paddingBottom } = containerStyle ?? {};
            const { borderTopWidth, borderBottomWidth } = elStyle ?? {};
            return (
                getAttr(height) -
                (getAttr(paddingTop) +
                    getAttr(paddingBottom) +
                    getAttr(borderTopWidth) +
                    getAttr(borderBottomWidth))
            );
        };

        const getWidth = (containerStyle, elStyle) => {
            const { width, paddingLeft, paddingRight } = containerStyle ?? {};
            const { borderLeftWidth, borderRightWidth } = elStyle ?? {};
            return (
                getAttr(width) -
                (getAttr(paddingLeft) +
                    getAttr(paddingRight) +
                    getAttr(borderLeftWidth) +
                    getAttr(borderRightWidth))
            );
        };

        if (containerRef?.current && elRef?.current) {
            let containerStyle = window.getComputedStyle(containerRef.current);
            let elStyle = window.getComputedStyle(elRef.current);
            let containerWidth = getWidth(containerStyle, elStyle);
            let containerHeight = getHeight(containerStyle, elStyle);
            let itemsPerX =
                (Math.floor(containerWidth / itemWidth) || 1) - xTrim;
            let itemsPerY =
                (Math.floor(containerHeight / itemHeight) || 1) - yTrim;
            setPageAmount(itemsPerY * itemsPerX);
        }
    }, [
        containerRef,
        elRef,
        itemWidth,
        itemHeight,
        setPageAmount,
        xTrim,
        yTrim,
        windowSize.width,
        windowSize.height,
        loaded,
    ]);
};

export default usePaginatedEl;
