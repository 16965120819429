import Container from "./styles";
import Book from "components/Book";

const Bookshelf = ({ bookshelfRef, books = [], theme = "light" }) => {
    return (
        <Container ref={bookshelfRef} theme={theme}>
            <div className="books">
                {books?.map(({ name, url }, i) => (
                    <Book key={i} name={name} url={url} theme={theme} />
                ))}
            </div>
        </Container>
    );
};

export default Bookshelf;
