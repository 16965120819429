import { useEffect, useState } from "react";

import { getDocs } from "db";

const useConfig = (configKey) => {
    const [config, setConfig] = useState(undefined);

    useEffect(() => {
        (async () => {
            let configDocs = await getDocs({
                collectionName: "config",
            });
            let configVal = configDocs?.[0]?.[configKey];
            setConfig(configVal);
        })();
    }, [configKey]);

    return config;
};

export default useConfig;
