import { BrowserRouter } from "react-router-dom";

import AppRoutes from "./routes/AppRoutes";
import GlobalStyles from "./styles/global";
import ScrollToTop from "utils/ScrollToTop";

function App() {
    return (
        <>
            <GlobalStyles />
            <BrowserRouter>
                <ScrollToTop />
                <AppRoutes />
            </BrowserRouter>
        </>
    );
}

export default App;
