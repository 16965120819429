import styled from "styled-components";

import leftArrow from "images/reliquary/left-arrow.png";
import rightArrow from "images/reliquary/right-arrow.png";

const Container = styled.div`
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .slick-prev:before,
    .slick-next:before {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 5vw;
        height: 5vw;
        filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5));
        transition: all 0.5s;
        z-index: 100;
        content: "";
    }

    .slick-prev:before {
        background: url(${leftArrow}) center/contain no-repeat;
    }

    .slick-next:before {
        background: url(${rightArrow}) center/contain no-repeat;
    }

    .slick-prev,
    .slick-next {
        z-index: 100;
    }

    .slick-prev {
        left: 120px;
    }

    .slick-next {
        right: 120px;
    }

    .slick-dots li {
        margin: 0 15px;
    }

    .slick-dots li:not(:last-of-type)::after {
        position: absolute;
        right: -27px;
        top: 50%;
        transform: translateY(-50%);
        width: 25px;
        height: 1px;
        background-color: #fff;
        content: "";
    }

    .slick-dots li,
    .slick-dots li button,
    .slick-dots li button:before {
        width: 30px;
        height: 30px;
    }

    .slick-dots li button {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0.5;
    }

    .slick-dots li.slick-active button {
        opacity: 1;
        filter: drop-shadow(0px 0px 7px rgba(255, 255, 255, 0.5));
    }

    .slick-dots li button:before {
        display: none;
    }
`;

export default Container;
