import $ from "jquery";

class PerspectiveHover {
    constructor(el, parent, intensity) {
        var self = this;
        // var elClass = el.replace(/\./g, "");
        var parentClass = parent.replace(/\./g, "");
        parent = document.getElementsByClassName(parentClass);
        var $parent = $(parent);
        // var els = document.getElementsByClassName(elClass);

        this.perspective = function (e, el) {
            var elX = el.getBoundingClientRect().left;
            var elY = el.getBoundingClientRect().top;
            var elWidth = el.offsetWidth;
            var elHeight = el.offsetHeight;

            var mouseX = e.clientX;
            var mouseY = e.clientY;

            var valY = -(
                ((elWidth / 2 - (mouseX - elX)) / (elWidth / 2)) *
                intensity
            );
            var valX =
                ((elHeight / 2 - (mouseY - elY)) / (elHeight / 2)) * intensity;

            el.style.webkitTransform =
                "rotateX(" +
                valX.toFixed(1) +
                "deg) rotateY(" +
                valY.toFixed(1) +
                "deg)"; // need webkit transform for this to work in safari 8
            el.style.transform =
                "rotateX(" +
                valX.toFixed(1) +
                "deg) rotateY(" +
                valY.toFixed(1) +
                "deg)"; // toFixed to round decimal values
        };

        this.anim = function (el, valX, valY, time) {
            animate({
                time: time, // time in seconds
                run: function (rate) {
                    el.style.webkitTransform =
                        "rotateX(" +
                        rate * valX +
                        "deg) rotateY(" +
                        rate * valY +
                        "deg)";
                    el.style.transform =
                        "rotateX(" +
                        rate * valX +
                        "deg) rotateY(" +
                        rate * valY +
                        "deg)";
                },
            });

            function animate(item) {
                var duration = 1000 * item.time;
                var end = +new Date() + duration;

                var step = function () {
                    var current = +new Date();
                    var remaining = end - current;

                    if (remaining < 60) {
                        item.run(0); // 1 = progress is at 100%
                        return;
                    } else {
                        var rate = remaining / duration;
                        item.run(rate);
                    }
                    window.requestAnimationFrame(step);
                };
                step();
            }
        };

        this.getTransforms = function (el) {
            var st = window.getComputedStyle(el, null);
            // var tr = st.getPropertyValue("transform");
            var matrix = st.getPropertyValue("transform");
            var webkitMatrix = st.getPropertyValue("-webkit-transform");
            var rotateX = 0;
            var rotateY = 0;
            // var rotateZ = 0;

            if (matrix !== "none") {
                // for safari
                if (!webkitMatrix === "") {
                    matrix = webkitMatrix;
                }

                // calculate the values of the rotation
                var values = matrix.split("(")[1].split(")")[0].split(",");
                var pi = Math.PI;
                var sinB = parseFloat(values[8]);
                var b = (Math.asin(sinB) * 180) / pi;
                var cosB = Math.cos((b * pi) / 180);
                var matrixVal10 = parseFloat(values[9]);
                var a = (Math.asin(-matrixVal10 / cosB) * 180) / pi;
                rotateX = a;
                rotateY = b;

                return [rotateX, rotateY];
            }
        };

        $parent.on("mousemove", el, function (e) {
            self.perspective(e, this);
        });

        $parent.on("mouseleave", el, function (e) {
            self.anim(
                this,
                self.getTransforms(this)[0],
                self.getTransforms(this)[1],
                0.3
            );
        });
    }
}

export default PerspectiveHover;
