import styled from "styled-components";

import getPercRange from "utils/getPercRange";
import sandBlue from "images/hourglasses/sand-v2-blue.gif";
import sandGreen from "images/hourglasses/sand-v2-green.gif";
import sandRed from "images/hourglasses/sand-v2-red.gif";
import sandYellow from "images/hourglasses/sand-v2-yellow.gif";

const animDuration = "2.5s";
const minSandPos = -250;
const maxSandPos = 0;
const sandColors = {
    blue: sandBlue,
    green: sandGreen,
    red: sandRed,
    yellow: sandYellow,
};

const getSand = (color) => sandColors?.[color] ?? sandRed;

const Container = styled.div`
    width: 25%;
    height: 100%;

    input {
        display: none;
        pointer-events: none;
        opacity: 0;
    }

    .hourglass-wrapper {
        height: 100%;
        width: 100%;
        position: relative;
    }

    .hourglass {
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        opacity: 0.001;
        transition: opacity 0.42s 0.33s ease-out;
        overflow: hidden;
    }

    .hourglass.visible {
        opacity: 1;
    }

    .hourglass-part {
        left: 8%;
        width: 84%;
        height: 100%;
        position: relative;
        background-color: rgb(255 255 255 / 38%);
        z-index: 1;
        backdrop-filter: brightness(0.45) blur(1px);
        overflow: hidden;
    }

    .hourglass-part:before {
        background-color: ${({ color }) => color ?? "red"};
        content: "";
        position: absolute;
        left: -2px;
        right: 0;
        width: 4px;
        height: 100%;
        margin: 0 auto;
        box-shadow: 0 0 17px 2px ${({ color }) => color ?? "red"},
            0 0 50px 1px rgb(212 255 14 / 27%);
        opacity: 0;
    }

    .hourglass-wrapper .visible .hourglass-part-B:before {
        opacity: 1;
        animation: sandFalling ${animDuration} forwards;
    }

    @keyframes sandFalling {
        0%,
        10% {
            opacity: 0;
        }
        12%,
        96% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .hourglass-part-B {
        border-top-left-radius: ${({ radius }) => radius ?? "50% 85%"};
        border-top-right-radius: ${({ radius }) => radius ?? "50% 85%"};
        box-shadow: -6px -32px 34px 9px inset #4f4f4f,
            0px -40px 60px 9px inset rgb(74 35 191 / 27%);
    }

    .hourglass-part .sand-wrapper {
        position: absolute;
        bottom: 0%;
        width: 100%;
        height: 100%;
    }

    .hourglass-part .sand-inner {
        width: 100%;
        height: 100%;
        transition: 6s linear;
    }

    .hourglass-part .sand {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(${({ color }) => getSand(color)});
        background-position-x: center;
        background-position-y: ${minSandPos}%;
        background-size: 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        transition: ${animDuration} cubic-bezier(0.47, 0, 0.745, 0.715);
    }

    .hourglass-wrapper .visible .hourglass-part .sand {
        background-position-y: ${({ position }) =>
            getPercRange(position ?? 100, minSandPos, maxSandPos)}%;
    }

    @keyframes fadeAwaySand {
        0%,
        97% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .hourglass-wrapper label {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 10;
        cursor: pointer;
    }

    .hourglass-wrapper label[for="hourglass1"],
    .hourglass-wrapper label[for="hourglass2"] {
        pointer-events: none;
    }

    .change-blocker {
        pointer-events: none;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 12;
    }

    .hourglass-wrapper .change-blocker {
        animation: blocking1 6s linear;
    }

    @keyframes blocking1 {
        0%,
        99.9999% {
            pointer-events: initial;
        }
    }

    .hourglass-wrapper .visible .change-blocker {
        animation: blocking2 6s linear;
    }

    @keyframes blocking2 {
        0%,
        99.9999% {
            pointer-events: initial;
        }
    }
`;

export default Container;
