import styled from "styled-components";

const Container = styled.div`
    height: calc(${({ height }) => height ?? "2vh"} - 1px);
    opacity: 0.85;

    a {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
    }
`;

export default Container;
