import { Stack } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import $ from "jquery";

import BannerNav from "components/BannerNav";
import Container from "./styles";
import RadialNav from "components/RadialNav";
import candleIcon from "images/candle-icon.png";
import creatureIcon from "images/creature-icon.png";
import hatIcon from "images/hat-icon.png";
import hogwartsIcon from "images/hogwarts-icon.png";
import housesBanner from "images/houses-banner.png";
import housesTitle from "images/ampulhetas-title.png";
import itemsBanner from "images/items-banner.png";
import itemsTitle from "images/items-title.png";
import libraryBanner from "images/library-banner.png";
import libraryTitle from "images/library-title.png";
import middleLogo from "images/middle-logo.png";
import plantsIcon from "images/plants-icon.png";
import potionsIcon from "images/potions-icon.png";
import skillsBanner from "images/skills-banner.png";
import skillsTitle from "images/skills-title.png";
import spellIcon from "images/spell-icon.png";

const Home = () => {
    const dispatch = useDispatch();
    const isRadialOpen = useSelector((state) => state.layout.isRadialOpen);
    const features = [
        {
            title: "Fauna Mágica",
            icon: creatureIcon,
        },
        {
            title: "Flora Mágica",
            icon: plantsIcon,
        },
        {
            title: "Hogwarts",
            icon: hogwartsIcon,
        },
        {
            title: "Habilidades",
            icon: spellIcon,
        },
        {
            title: "Poções",
            icon: potionsIcon,
        },
        {
            title: "Aulas",
            icon: hatIcon,
        },
        {
            title: "Biblioteca",
            icon: candleIcon,
        },
    ];
    const leftNav = [
        {
            title: "Habilidades",
            titleImg: skillsTitle,
            icon: skillsBanner,
            yPos: "center 25%",
            to: "habilidades",
        },
        {
            title: "Biblioteca",
            titleImg: libraryTitle,
            icon: libraryBanner,
            yPos: "bottom",
            to: "biblioteca",
        },
    ];
    const rightNav = [
        {
            title: "Ampulhetas",
            titleImg: housesTitle,
            icon: housesBanner,
            yPos: "center 40%",
            to: "ampulhetas",
        },
        {
            title: "Relicário",
            titleImg: itemsTitle,
            icon: itemsBanner,
            yPos: "center top",
            to: "relicario",
        },
    ];

    useEffect(() => {
        dispatch({ type: "layout/resetBg" });
        dispatch({ type: "layout/hasBgGradient", payload: false });
        dispatch({ type: "layout/resetBgOverlay" });
    }, [dispatch]);

    useEffect(() => {
        setTimeout(function () {
            $(".circle").show(600);
            $(".secret-text").css({ display: "flex" });
        }, 1000);
    }, []);

    return (
        <Container>
            <Stack
                position="absolute"
                left="50%"
                top="50%"
                direction="row"
                justifyContent="space-between"
                width={0.9}
                height={1}
                sx={{
                    transform: "translate(-50%, -50%)",
                    opacity: +isRadialOpen,
                    pointerEvents: isRadialOpen ? "auto" : "none",
                    transition: "opacity 1s",
                }}
            >
                <BannerNav items={leftNav} />
                <BannerNav items={rightNav} />
            </Stack>
            <Stack
                className="secret-text"
                display="none"
                position="absolute"
                left="50%"
                top="50%"
                direction="row"
                justifyContent="space-between"
                width={0.8}
                height="fit-content"
                sx={{
                    transform: "translate(-50%, -50%)",
                    fontFamily: "Satisfy",
                    fontSize: "2.8vw",
                    color: "#fff",
                    opacity: +!isRadialOpen,
                    pointerEvents: isRadialOpen ? "none" : "auto",
                    filter: "drop-shadow(0px 0px 7px rgba(255,255,255,0.5))",
                    transition: "opacity 1s",
                }}
            >
                <span>Mais informações</span>
                <span>vindo em breve...</span>
            </Stack>
            <RadialNav
                selectorIcon={middleLogo}
                items={features}
                iconSize="4vw"
                dialSize="2.3vw"
            />
        </Container>
    );
};

export default Home;
