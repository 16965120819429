import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Container from "./styles";
import Hourglass from "components/Hourglass";
import HousePointsNav from "components/HousePointsNav";
import bg from "images/hourglasses/bg.png";
import hordiv from "images/hourglasses/hordiv.png";
import useHouses from "hooks/useHouses";
import useConfig from "hooks/useConfig";

const Hourglasses = () => {
    const [started, setStarted] = useState(false);
    const dispatch = useDispatch();
    const houses = useHouses();
    const leftHouses = houses?.length ? [houses[0], houses[1]] : [];
    const rightHouses = houses?.length ? [houses[2], houses[3]] : [];
    const maxPoints = useConfig("maxHousePoints");

    useEffect(() => {
        setStarted(true);
    }, [maxPoints]);

    useEffect(() => {
        dispatch({ type: "layout/setBg", payload: bg });
        dispatch({ type: "layout/hasBgGradient", payload: false });
        dispatch({ type: "layout/removeBgOverlay" });
    }, [dispatch]);

    return (
        <Container>
            <Stack
                position="absolute"
                left="50%"
                top="50%"
                direction={{ sm: "row", xs: "column" }}
                spacing={{ sm: 0, xs: 1 }}
                justifyContent={{
                    lg: "space-between",
                    sm: "center",
                }}
                alignItems="center"
                width={{ sm: 0.85, xs: 1 }}
                divider={
                    <Box display={{ sm: "none", xs: "block" }} width="50vw">
                        <img
                            src={hordiv}
                            alt="Divisória"
                            style={{ width: "100%" }}
                        />
                    </Box>
                }
                sx={{ transform: "translate(-50%, -50%)" }}
            >
                <HousePointsNav
                    items={leftHouses}
                    spacing={{ sm: 7, xs: 1 }}
                    divider={hordiv}
                    align={{ sm: "flex-start", xs: "center" }}
                />
                <HousePointsNav
                    items={rightHouses}
                    spacing={{ sm: 7 }}
                    divider={hordiv}
                    align={{ sm: "flex-end", xs: "center" }}
                />
            </Stack>
            <Hourglass
                display={{ lg: "block", xs: "none" }}
                houses={houses}
                height={0.85}
                maxPoints={maxPoints}
                started={started}
            />
        </Container>
    );
};

export default Hourglasses;
