import styled from "styled-components";

import frame from "images/skills/mini-card-frame.png";
import frameMask from "images/skills/mini-card-frame-mask.png";

const Container = styled.div`
    .simplebar-content,
    .simplebar-wrapper {
        width: 100%;
        height: 100%;
    }

    .simplebar-wrapper {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .simplebar-offset {
        overflow-y: hidden;
    }

    .js-perspective {
        filter: drop-shadow(10px 10px 10px rgb(0, 0, 0, 0.5));
        transition: 0.2s;
        width: 100%;
        height: 80%;
    }

    .perspective-card-wrap {
        perspective: 1000px;
        perspective-origin: center;
        display: inline-block;
        width: 100%;
        height: 100%;
    }

    .perspective-card {
        color: white;
        text-transform: uppercase;
        font-family: "open sans";
        text-align: center;
        /* box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15); */

        /* necessary for effect */
        position: relative;
        transition: none;
        backface-visibility: hidden;
        transform: rotateX(0deg) rotateY(0deg);
        transform-style: preserve-3d;
        will-change: transform;
    }

    .skill-card-frame,
    .skill-card {
        width: min(20vw, 220px);
        height: 100%;
    }

    .skill-card-frame {
        position: relative;
        background: url(${frame}) center/contain no-repeat;
    }

    .skill-card {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 95%;
        height: 100%;
        mask: url(${frameMask}) center/contain no-repeat;
        transform: translate(-50%, -50%);
    }

    .octatitle {
        position: absolute;
        left: 50%;
        top: calc(50% + 4vw);
        transform: translateX(-50%) translateZ(40px);
    }
`;

export default Container;
