import Anchor from "components/Anchor";
import OctaTitle from "components/OctaTitle";

const SkillCard = ({ image, name }) => {
    return (
        <div className="js-perspective">
            <Anchor to={encodeURIComponent(name)}>
                <div className="perspective-card-wrap">
                    <div className="js-perspective-card perspective-card skill-card-frame">
                        <div
                            className="skill-card"
                            style={{
                                background: `url(${image}) center/contain no-repeat`,
                            }}
                        ></div>
                        <OctaTitle color="#132d4d">{name}</OctaTitle>
                    </div>
                </div>
            </Anchor>
        </div>
    );
};

export default SkillCard;
