import { isEmpty } from "richierich";
import { useEffect, useState } from "react";

import { getDocs } from "db";

const useHouses = () => {
    const [houses, setHouses] = useState([
        {
            color: "green",
            color2: "#4ebe53",
            icon: "https://i.imgur.com/V9YUeJd.png",
            name: "Sonserina",
            points: 0,
            slogan: "Ambição",
        },
        {
            color: "blue",
            color2: "#59aaff",
            icon: "https://i.imgur.com/dixBaVz.png",
            name: "Corvinal",
            points: 0,
            slogan: "Sabedoria",
        },
        {
            color: "red",
            color2: "#ff5858",
            icon: "https://i.imgur.com/NcHjTXl.png",
            name: "Grifinória",
            points: 0,
            slogan: "Coragem",
        },
        {
            color: "yellow",
            color2: "#dfcf19",
            icon: "https://i.imgur.com/TzTgU3D.png",
            name: "Lufa-Lufa",
            points: 0,
            slogan: "Amizade",
        },
    ]);

    useEffect(() => {
        (async () => {
            let housesDocs = await getDocs({
                collectionName: "houses",
                orders: ["order"],
            });
            if (isEmpty(housesDocs)) {
                return;
            }
            setHouses(housesDocs);
        })();
    }, []);

    return houses;
};

export default useHouses;
